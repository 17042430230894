import {Component, OnInit} from "@angular/core";
import {CmsApiService} from "../../../core/cms-api.service";
import {ModelStore} from "../../../core/ModelStore/ModelStore";
import {ReportUserGenerated} from "../../../core/ModelStore/models/ReportUserGenerated";
import {ModelCacheArray} from "../../../core/ModelStore/ModelCacheArray";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {saveAs} from "file-saver";
import JSZip from "jszip";

@Component({
  selector: 'report-generated',
  templateUrl: './report-generated.component.html',
  styleUrls: ['./report-generated.component.scss']
})
export class ReportGeneratedComponent implements OnInit {
  generatedReports: ModelCacheArray<ReportUserGenerated>;
  filteredReports: ReportUserGenerated[];
  selectedReports: string[] = [];
  searchText: string;

  constructor(private readonly cms: CmsApiService,
              private modelStore: ModelStore) {
    this.getPdfs = this.getPdfs.bind(this);
    this.deleteReports = this.deleteReports.bind(this);
  }

  async ngOnInit(): Promise<void> {
    this.generatedReports = this.modelStore.findAllModels('report_user_generated');
    await this.generatedReports.loading;

    this.generatedReports.forEach(async (report) => {
      let imgUrl = await this.cms.fetchImageUrlFromDmsId(report.dmsId);
      report.thumbnailUrl = imgUrl.url;
    })

    this.filterReports()


    let reportViewTypes = this.modelStore.findAllModels('report_view_type');
    await reportViewTypes.loading;

    if (this.generatedReports.length > 0) {
      this.generatedReports.forEach(async report => {
        if (report.reportViewTypeId) {
          report.reportViewType = await this.modelStore.findModel('report_view_type', report.reportViewTypeId);
        }
      });
    }
    /* TODO: only show generated reports

    this.generatedReports = this.modelStore.findAllModels('report_user_generated');
    await this.generatedReports.loading;

    let reportViewTypes = this.modelStore.findAllModels('report_view_type');
    await reportViewTypes.loading;

    if (this.generatedReports.length > 0) {
      this.generatedReports.forEach(async report => {
        if (report.reportViewTypeId) {
          report.reportViewType = await this.modelStore.findModel('report_view_type', report.reportViewTypeId);
        }
      });
    }
     */
  }

  deleteReports() {
    console.log('DELETE REPORT: ', this.selectedReports);
  }

  async getPdfs() {
    console.log('GET PDF: ', this.selectedReports, this.generatedReports);

    console.log('report.id vs this.selectedReports[0]: ', this.selectedReports[0]);

    if (this.selectedReports.length === 1) {
      await this.downloadSinglePdf();
    } else if (this.selectedReports.length > 1) {
      await this.downloadMultiplePdfs();
    }
  }

  async downloadSinglePdf() {
    let downloadUrl = await this.cms.requestReportPdfFromDms(this.selectedReports[0]);
    if (downloadUrl.url) {
      let pdfFile = await fetch(downloadUrl.url);
      let pdfFileBlob = await pdfFile.blob();
      saveAs(pdfFileBlob, this.selectedReports[0] + '.pdf');
    }
  }

  async downloadMultiplePdfs() {
    const zip = new JSZip();

    let pdfUrls = [];
    for (const reportId of this.selectedReports) {
      let pdfUrl = await this.cms.requestReportPdfFromDms(reportId);
      pdfUrls.push(pdfUrl);
    }

    console.log('PDF URLS: ', pdfUrls);

    // Fetch each PDF file
    const filePromises = pdfUrls.map(async (downloadUrl, index) => {
      const response = await fetch(downloadUrl.url);
      const blob = await response.blob();
      zip.file(`${downloadUrl.id}.pdf`, blob);
    });

    // Wait for all files to be fetched and added to the zip
    await Promise.all(filePromises);

    // Generate the zip file as a blob
    zip.generateAsync({ type: 'blob' }).then(function (zipBlob) {
      // Use saveAs to save the generated zip file
      saveAs(zipBlob, 'multiple_reports.zip');
    });
  }

  reportSelectionChanged(event: MatCheckboxChange, templateId : string) {
    console.log('REPORT SELECTION CHANGED: ', event, templateId);
    if (event.checked && !this.selectedReports.includes(templateId)) {
      this.selectedReports.push(templateId);
    } else if (!event.checked && this.selectedReports.includes(templateId)) {
      this.selectedReports.splice(this.selectedReports.indexOf(templateId), 1);
    }
  }

  filterReports() {
    const searchTextLower = this.searchText ? this.searchText.toLowerCase() : '';

    this.filteredReports = this.generatedReports.filter(report => {
      const name = report.name ? report.name.toLowerCase() : '';
      const shortDescription = report.shortDescription ? report.shortDescription.toLowerCase() : '';
      const reportType : string = report.reportViewType?.name ? report.reportViewType.name.toLowerCase() : '';

      return report.reportStatus === 'generated' && (name.includes(searchTextLower) || shortDescription.includes(searchTextLower) || reportType.includes(searchTextLower));
    });
  }

}
