import {Injectable} from '@angular/core';
import {AConst} from '../core/a-const.enum';
import {CommonsService} from '../core/commons.service';
import {SettingsService} from '../core/settings.service';
import {SearchParameterService} from '../object-search/search-parameter.service';
import {SearchObject} from '../core/definitions/search-object';
import {SearchResultSelectionsService} from '../object-search/search-result-selections.service';
import {FieldValueService} from '../core/field-value.service';
import {SuperObjectModel} from '../core/definitions/super-object-model';
import {CheckFilter, Facet, FacetItem, SearchView, SearchViewPath} from '../core/definitions/search-objects';
import {SearchResult} from '../core/definitions/search-result';
import {OperationTarget} from '../core/definitions/operation-target.enum';
import {OperationDialogService} from '../operations/operation-dialog.service';
import {OperationService} from '../operations/operation.service';
import {SearchParametersForOverview} from '../core/definitions/search-parameters';
import {SolrFilterService} from '../core/solr-filter.service';
import {BaseModel} from '../core/definitions/base-model';
import {MetaField} from '../core/definitions/meta-field';
import {FieldConditionService} from '../core/field-condition.service';
import {FieldParameters} from '../core/definitions/field-parameters';
import {ModelsService} from '../core/models.service';
import {ContentMenuObjectSourceList} from '../core/definitions/object-content-tab/content-menus';
import {
  ContentListItem,
  ContentListPageItem,
  ContentListSourceContainer
} from '../core/definitions/object-content-tab/content-list-source-container';
import {SearchContainer} from '../core/definitions/search-container';
import {OperationContainer} from '../core/definitions/operation-container';
import {ObjectView} from "../core/definitions/object-view";
import {SearchService} from "../core/search.service";
import {SearchFilterService} from "../object-search/search-filter.service";
import {QueryFieldMenuService} from "../object-search/query-field-menu.service";

@Injectable({
  providedIn: 'root'
})
export class ObjectContentListSourceArrayService {

  constructor(private commons: CommonsService,
              private settings: SettingsService,
              private searchService: SearchService,
              private searchParameterService: SearchParameterService,
              private searchResultSelectionsService: SearchResultSelectionsService,
              private fieldValueService: FieldValueService,
              private operationDialogService: OperationDialogService,
              private operationService: OperationService,
              private solrFilter: SolrFilterService,
              private fieldCondition: FieldConditionService,
              private modelsService: ModelsService,
              private searchFilterService: SearchFilterService,
              private queryFieldMenuService: QueryFieldMenuService) {
  }

  createContentListSource(sourceListMenu: ContentMenuObjectSourceList,
                          ownerObject: SuperObjectModel,
                          parentObject: SuperObjectModel): ContentListSourceContainer {
    const contentListSource = new ContentListSourceContainer(sourceListMenu, ownerObject);
    this.setContentListObject(parentObject, contentListSource);
    this.setSearchContainer(sourceListMenu, contentListSource);
    this.setSourceListOperationsContainer(sourceListMenu, contentListSource);
    this.setSubMenus(sourceListMenu, ownerObject, parentObject, contentListSource);
    return contentListSource;
  }

  setContentListObject(parentObject: SuperObjectModel, contentListSource: ContentListSourceContainer) {
    contentListSource.parentObject = parentObject;
    contentListSource.array = this.getSourceItems(parentObject, contentListSource);
  }

  getSourceItems(parentObject: SuperObjectModel, contentListSource: ContentListSourceContainer) {
    let res = [];
    if (parentObject[contentListSource.fieldName] === undefined) {
      console.error(`Object type ${parentObject.object_type} is missing array property ${contentListSource.fieldName}`);
      parentObject[contentListSource.fieldName] = [];
    }
    const sourceArray = parentObject[contentListSource.fieldName];
    if (!contentListSource.sourceArrayFilters) {
      return sourceArray;
    }
    for (const item of sourceArray) {
      for (const [filterName, filterValue] of Object.entries(contentListSource.sourceArrayFilters)) {
        const filterValues: any[] = Array.isArray(filterValue) ? filterValue : [filterValue];
        res = res.concat(this.loopFilterValuesGetSourceItems(item, filterValues, filterName));
      }
    }
    return res;
  }

  private loopFilterValuesGetSourceItems(sourceArrayItem: any, filterValues: any[], filterName: string) {
    let res = [];
    for (const value of filterValues) {
      if (sourceArrayItem[filterName] !== undefined && sourceArrayItem[filterName] === value) {
        res.push(sourceArrayItem);
      }
    }
    return res;
  }

  // eslint-disable-next-line max-len
  async setPageItems(contentListSource: ContentListSourceContainer,
                     resetPage: boolean,
                     keepRelatedObjects: boolean,
                     object?: SuperObjectModel): Promise<void> {
    if (object) {
      this.setContentListObject(object, contentListSource);
    }
    contentListSource.page = resetPage ? 1 : contentListSource.page;
    await this.getSourceArrayItems(contentListSource, keepRelatedObjects);
    this.sortSourceArrayItems(contentListSource);
    const pageItems: Array<ContentListPageItem> = [];
    for (const listItem of contentListSource.items) {
      let pageItem: ContentListPageItem;
      if (contentListSource.displayRelatedObject) {
        const relatedObject = this.getRelatedObject(contentListSource, listItem);
        if (relatedObject) {
          pageItem = new ContentListPageItem(listItem, relatedObject, false);
        }
      } else {
        pageItem = new ContentListPageItem(listItem, null, false);
      }
      if (pageItem) {
        pageItems.push(pageItem);
      }

    }
    this.setThumbnailInfo(pageItems);
    contentListSource.totalPageItems = pageItems;
    contentListSource.displayAll = pageItems.length < contentListSource.maxRows ? false : contentListSource.displayAll;
    contentListSource.pageItems = this.loopGetPageItems(pageItems, contentListSource);
    contentListSource.searchContainer.searchResult.search_count = contentListSource.items.length;
  }

  private setSearchContainer(menu: ContentMenuObjectSourceList, contentListSource: ContentListSourceContainer) {
    // Need a mock search container in order to re-use the component "SearchQuery"
    contentListSource.searchContainer = new SearchContainer();
    if (menu.search_view) {
      contentListSource.searchContainer.searchView = menu.search_view;
      contentListSource.searchContainer.currentPathView = menu.search_view.paths[0];
      const queryMenus = this.queryFieldMenuService.getQueryMenusForCurrentPathView(contentListSource.searchContainer);
      if (queryMenus.menus) {
        contentListSource.searchContainer.queryContainer.selectedQueryMenu = queryMenus.menus[0];
        // eslint-disable-next-line max-len
        contentListSource.searchContainer.queryContainer.queryPlaceholder = contentListSource.searchContainer.queryContainer.selectedQueryMenu.description;
      }
      // This will make a "deep clone" of filter groups in order to avoid filters from being preserved
      contentListSource.filterGroups = this.searchFilterService.getCheckFilterGroupsFromPathView(
        contentListSource.searchContainer.currentPathView)
    } else {
      contentListSource.searchContainer.currentPathView = new SearchViewPath();
      contentListSource.searchContainer.currentPathView.search_view = new SearchView();
    }
    contentListSource.searchContainer.searchResult = new SearchResult();
    contentListSource.searchContainer.searchResult.search_count = 0;
  }

  private setSubMenus(menu: ContentMenuObjectSourceList, ownerObject: SuperObjectModel, parentObject: SuperObjectModel,
                      contentListSource: ContentListSourceContainer) {
    if (menu.sub_menus) {
      contentListSource.subMenus = [];
      for (const subMenu of menu.sub_menus) {
        contentListSource.subMenus.push(this.createContentListSource(
          <ContentMenuObjectSourceList>subMenu, ownerObject, parentObject));
      }
    }
  }


  private setSourceListOperationsContainer(menu: ContentMenuObjectSourceList, contentListSource: ContentListSourceContainer) {
    let operationContainer: OperationContainer;
    if (menu.operations) {
      // Use operations defined in content menu, e.g. spectrum procedure set object status
      operationContainer = new OperationContainer(OperationTarget.SEARCH_VIEW);
      operationContainer.searchContainer = contentListSource.searchContainer;
      operationContainer.operations = menu.operations;
      operationContainer.art = contentListSource.parentObject;
      contentListSource.operationContainer = operationContainer;
      contentListSource.searchContainer.selections.selectItemCallback = () => {
        this.setOperationContextObjects(contentListSource);
      };
    } else if (menu.get_operations) {
      // Dynamically get operations based on selected objects, like in 'regular' search views
      const searchContainer = contentListSource.searchContainer;
      operationContainer = new OperationContainer(OperationTarget.CONTENT_LIST_VIEW);
      operationContainer.searchContainer = searchContainer;
      contentListSource.operationContainer = operationContainer;
      operationContainer.art = contentListSource.parentObject;
      searchContainer.selections.selectItemCallback = () => {
        this.setOperationContextObjects(contentListSource);
        this.setOperations(contentListSource).then();
      };
    }
    // TODO: The two types of operation menus above should be combined into one (the dynamic one)
    if (operationContainer) {
      operationContainer.getTargetId = () => {
        return operationContainer.art.artifact_id;
      };
      operationContainer.openOperationDialogFn = () => {
        this.operationDialogService.openOperationDialog(operationContainer).then(data => {
          if (data.refreshView && contentListSource.refreshCallback) {
            contentListSource.refreshCallback();
          }
        });
      };
    }
  }

  private setOperationContextObjects(contentListSource: ContentListSourceContainer) {
    this.operationService.setOperationContextObjects(
      contentListSource.operationContainer,
      this.searchResultSelectionsService.getCleanItems(
        contentListSource.searchContainer));
  }

  private async setOperations(contentListSource: ContentListSourceContainer) {
    await this.operationService.setOperations(contentListSource.operationContainer, contentListSource.searchContainer);
  }

  private async getSourceArrayItems(contentListSource: ContentListSourceContainer, keepRelatedObjects: boolean): Promise<void> {
    if (contentListSource && contentListSource.array && contentListSource.array.length) {
      if (!keepRelatedObjects && contentListSource.displayRelatedObject) {
        await this.getSourceArrayRelatedObjectsSetContentListSourceItems(contentListSource);
      } else {
        this.setContentListSourceItems(contentListSource);
      }
    }
  }

  private sortSourceArrayItems(contentListSource: ContentListSourceContainer) {
    let objectList = contentListSource.items;
    let relatedObjectList = Object.values(contentListSource.relatedObjects);
    if (contentListSource.order && objectList.length && relatedObjectList.length) {
      const fieldVal = relatedObjectList[0][contentListSource.order.field_name];
      if (fieldVal !== undefined) {
        const fieldNames = [contentListSource.order.field_name];
        objectList = this.getSortedByRelatedObjectList(
          this.commons.sortArray(relatedObjectList, fieldNames, contentListSource.order.reverse),
          contentListSource.items.slice())
      } else {
        objectList = this.commons.sortArray(
          contentListSource.items,
          ['object', contentListSource.order.field_name],
          contentListSource.order.reverse);
      }
    }
    contentListSource.items = objectList;
  }

  private getSortedByRelatedObjectList(relatedObjectList: ObjectView[],
                                       contentListSourceItems: ContentListItem[]): ContentListItem[] {
    const objectList: ContentListItem[] = [];
    for (let relatedItem of relatedObjectList) {
      for (let t = 0; t < contentListSourceItems.length; t++) {
        const arrayItem = contentListSourceItems[t];
        if (relatedItem.$$relatedObjectId === arrayItem.object.artifact_id) {
          objectList.push(arrayItem);
          contentListSourceItems.splice(t, 1);
          break;
        }
      }
    }
    return objectList;
  }

  // eslint-disable-next-line max-len
  private loopGetPageItems(pageItems: Array<ContentListPageItem>, contentListSource: ContentListSourceContainer): Array<ContentListPageItem> {
    const res = [];
    let loopStart: number;
    let loopEnd: number;
    if (contentListSource.displayAll) {
      loopStart = 0;
      loopEnd = pageItems.length;
    } else {
      loopStart = (contentListSource.page - 1) * contentListSource.maxRows;
      loopEnd = Math.min(loopStart + contentListSource.maxRows, pageItems.length);
    }
    const selections = contentListSource.searchContainer.selections;
    const selectedItems = [...selections.selectedItems];
    for (let t = loopStart; t < loopEnd; t++) {
      res.push(pageItems[t]);
      this.setCheckedPageItem(pageItems[t], selectedItems, selections.allSelected);
    }
    return res;
  }

  private setCheckedPageItem(pageItem: ContentListPageItem, selectedItems: SearchObject[], allSelected: boolean) {
    if (allSelected) {
      pageItem.checked = true;
    } else if (selectedItems.length) {
      for (let s = 0; s < selectedItems.length; s++) {
        const itemId = this.getSelectedItemId(selectedItems[s]);
        if (itemId === pageItem.listItem.object.artifact_id) {
          pageItem.checked = true;
          selectedItems.splice(s, 1);
          break;
        }
      }
    }
  }

  private getSelectedItemId(selectedItem: SearchObject) {
    return selectedItem.artifact_id ? selectedItem.artifact_id : selectedItem['relation_id'];
  }

  private getRelatedObject(contentListSource: ContentListSourceContainer, item: ContentListItem) {
    return contentListSource.relatedObjects[item.object[contentListSource.relatedObjectIdField]];
  }

  private getRelatedObjectIds(contentListSource: ContentListSourceContainer) {
    return contentListSource.array.map(obj => obj[contentListSource.relatedObjectIdField]);
  }

  private async getSourceArrayRelatedObjectsSetContentListSourceItems(
    contentListSource: ContentListSourceContainer): Promise<void> {
    if (!contentListSource.displayRelatedObject) {
      return;
    }
    const objectIds = this.getRelatedObjectIds(contentListSource);
    contentListSource.searchContainer.rows = objectIds.length;
    const searchParams = await this.searchParameterService.getSearchParams(
      contentListSource.searchContainer);
    if (!contentListSource.sourceArrayFilters) {
      if (contentListSource.relationSearchField) {
        searchParams.query = contentListSource.relationSearchField + ':' + contentListSource.parentObject.artifact_id;
      } else {
        searchParams.query = `{!join from=${contentListSource.fieldName}.${contentListSource.relatedObjectIdField}
    to=${contentListSource.relatedObjectContextIdField}}artifact_id:${contentListSource.parentObject.artifact_id}`;
      }
    } else {
      this.solrFilter.addFq(searchParams, contentListSource.relatedObjectContextIdField, objectIds);
      this.solrFilter.addFq(searchParams, 'meta_type',
        contentListSource.relatedMetaTypes ? contentListSource.relatedMetaTypes : null);
      this.solrFilter.addFq(searchParams, 'object_type',
        contentListSource.relatedObjectType ? [contentListSource.relatedObjectType] : null);
    }
    this.setFiltersFromFacets(contentListSource, searchParams);
    const searchRes = await this.searchService.searchWithOverview(searchParams);
    const objects = searchRes.artifacts;
    if (objects) {
      this.setContentListSourceItems(contentListSource, objects);
      await this.setSourceArrayRelatedObjectsFromSearchResult(contentListSource, objects);
      this.setSourceArrayFacetsFromSearchResult(contentListSource, searchRes);
      this.removedDeletedItemsFromSelectList(contentListSource);
    } else {
      console.warn('Something went wrong! ' + searchRes[AConst.STATUS_MSG]);
    }
  }

  private setFiltersFromFacets(contentListSource: ContentListSourceContainer, searchParams: SearchParametersForOverview) {
    const filterGroups = contentListSource.filterGroups;
    if (!filterGroups) {
      return;
    }
    const filters = {};
    for (const filterGroup of filterGroups) {
      for (const filterField of filterGroup.filters) {
        for (let facetItem of filterField.items || []) {
          if (facetItem.selected) {
            filters[filterField.name] = filters[filterField.name] || [];
            filters[filterField.name].push(facetItem.id);
          }
        }
      }
    }
    for (const [filterName, filterValues] of Object.entries(filters)) {
      this.solrFilter.addFq(searchParams, filterName, filterValues);
    }
  }

  private setContentListSourceItems(contentListSource: ContentListSourceContainer, relatedObjects?: SearchObject[]) {
    contentListSource.items = [];
    if (!relatedObjects) {
      for (const sourceArrayObj of contentListSource.array) {
        this.addContentListSourceItem(contentListSource, sourceArrayObj);
      }
    } else {
      for (let sourceArrayObj of contentListSource.array) {
        const sourceArrayItemId = sourceArrayObj[contentListSource.relatedObjectIdField];
        for (const relatedObject of relatedObjects) {
          if (sourceArrayItemId === relatedObject[contentListSource.relatedObjectContextIdField]) {
            this.addContentListSourceItem(contentListSource, sourceArrayObj);
            break;
          }
        }
      }
    }
  }

  private addContentListSourceItem(contentListSource: ContentListSourceContainer, sourceArrayObj: SearchObject) {
    const fields = this.getSourceArrayFields(sourceArrayObj);
    contentListSource.items.push(
      new ContentListItem(this.commons.sortArray(fields, 'order'), sourceArrayObj));
  }

  private async setSourceArrayRelatedObjectsFromSearchResult(contentListSource: ContentListSourceContainer, objects: SearchObject[]) {
    contentListSource.relatedObjects = {};
    for (const item of contentListSource.items) {
      for (const object of objects) {
        let contextArtifactIds = object[contentListSource.relatedObjectContextIdField];
        contextArtifactIds = Array.isArray(contextArtifactIds) ? contextArtifactIds : [contextArtifactIds];
        if (contextArtifactIds.indexOf(item.object[contentListSource.relatedObjectIdField]) !== -1) {
          const relatedObjectId = item.object[contentListSource.relatedObjectIdField];
          contentListSource.relatedObjects[relatedObjectId] = await this.getSourceArrayRelatedObject(object);
          contentListSource.relatedObjects[relatedObjectId].$$relatedObjectId = relatedObjectId;
        }
      }
    }
  }

  private getSourceArrayFields(obj: BaseModel) {
    const fields = [];
    const meta = this.getMeta(obj);
    let order = 0;
    for (const metaField of Object.values(meta)) {
      if (this.canShow(obj, metaField)) {
        let value = this.fieldValueService.getTextValue(obj, metaField, true);
        value = value !== undefined ? value : '-';
        fields.push({
          title: metaField.title || metaField.admin_title,
          value: value,
          order: order++
        });
      }
    }
    return fields;
  }

  private getMeta(obj: BaseModel) {
    let meta = obj.$$meta;
    if (!meta) {
      meta = this.modelsService.getModelMeta(obj.object_type) || {};
    }
    return meta;
  }

  private canShow(obj: BaseModel, metaField: MetaField): boolean {
    let res = false;
    if (metaField.display === 'yes') {
      res = true;
    }
    if (res) {
      const fieldIfs = this.fieldCondition.getFieldConditions(metaField);
      for (const fieldIf of fieldIfs || []) {
        if (fieldIf.if_type === 'show') {
          const fieldParameters = new FieldParameters();
          fieldParameters.field = metaField;
          fieldParameters.rootObject = <SuperObjectModel>obj;
          res = this.fieldCondition.runIfItem(fieldIf, fieldParameters);
        }
      }
    }
    return res;
  }

  private setSourceArrayFacetsFromSearchResult(contentListSource: ContentListSourceContainer, searchResult: SearchResult) {
    const facets = searchResult.facets;
    if (!(facets && contentListSource.filterGroups)) {
      return;
    }
    for (const filterGroup of contentListSource.filterGroups) {
      for (const filterField of filterGroup.filters) {
        this.loopFacetsSetFilterItems(facets, filterField)
      }
    }
  }

  private loopFacetsSetFilterItems(facets: Facet[], filterField: CheckFilter) {
    for (const facet of facets) {
      if (!(facet.f_name === filterField.name && !filterField.items)) {
        continue;
      }
      if (!filterField.value) {
        filterField.items = facet.items;
      } else {
        this.loopFacetItemsSetFilterItems(facet, filterField)
      }
    }
  }

  private loopFacetItemsSetFilterItems(facet: Facet, filterField: CheckFilter) {
    for (const facetItem of facet.items) {
      if (facetItem.id === filterField.value) {
        const item = <FacetItem>this.commons.copy(facetItem);
        item.name = filterField.title;
        filterField.items = [item];
      }
    }
  }

  private async getSourceArrayRelatedObject(object: SearchObject): Promise<ObjectView> {
    const relatedObjectView = this.commons.copy(object);
    relatedObjectView.overview = object.overview;
    relatedObjectView.icon = await this.settings.objectIcon(
      relatedObjectView.object_type, 0, object, true);
    return relatedObjectView;
  }

  private removedDeletedItemsFromSelectList(contentListSource: ContentListSourceContainer) {
    const deleteIndexList = [];
    for (const [index, selectedItem] of Object.entries(contentListSource.searchContainer.selections.selectedItems)) {
      const selectedItemId = this.getSelectedItemId(selectedItem);
      if (!contentListSource.relatedObjects[selectedItemId]) {
        deleteIndexList.push(index);
      }
    }
    if (deleteIndexList.length) {
      for (let t = deleteIndexList.length; t >= 0; t--) {
        this.searchResultSelectionsService.removeItemIdByIndex(
          contentListSource.searchContainer, deleteIndexList[t]);
      }
    }
  }

  private setThumbnailInfo(pageItems: Array<ContentListPageItem>) {
    for (const pageItem of pageItems) {
      if (pageItem.relatedObject && pageItem.relatedObject.thumbnail_url) {
        pageItem.thumbnailInfo = new SearchObject();
        pageItem.thumbnailInfo.thumbnail_url = pageItem.relatedObject.thumbnail_url;
      } else if (pageItem.relatedObject && pageItem.relatedObject.thumbnail_id) {
        pageItem.thumbnailInfo = new SearchObject();
        pageItem.thumbnailInfo.thumbnail_id = pageItem.relatedObject.thumbnail_id;
      } else if (pageItem.listItem.object.thumbnail_url) {
        pageItem.thumbnailInfo = new SearchObject();
        pageItem.thumbnailInfo.thumbnail_url = pageItem.listItem.object.thumbnail_url;
      } else if (pageItem.listItem.object.thumbnail_id) {
        pageItem.thumbnailInfo = new SearchObject();
        pageItem.thumbnailInfo.thumbnail_id = pageItem.listItem.object.thumbnail_id;
      }
    }
  }

}
