<div class="edit-report-template-container">
  <div class="edit-report-template-container-left">
    <h1>Tilpass Rapporten</h1>

    @if (!this.generatedReport) {
      <div>Laster...</div>
    } @else {

      @if (this.generatedReport.reportStatus === 'generated') {
        <div class="edit-report-header-area">
          <h1>Utvalg ikke mulig</h1>
          <div>
            Denne rapporten er ferdig generert. Endringer på utvalg er dermed ikke tilgjengelig.
          </div>
        </div>
      } @else {
        <div class="edit-report-header-area">
          <h1>Lagrede oppsett</h1>
          <div class="edit-report-input-field-three-button">
            <mat-form-field class="edit-report-input-field">
              <mat-label>Velg et lagret oppsett</mat-label>
              <mat-select [(ngModel)]="selectedReportUserTemplateId" (selectionChange)="onUserTemplateSelected($event)">
                @for (userTemplate of reportUserTemplates; track userTemplate.name) {
                  <mat-option [value]="userTemplate.id">{{ userTemplate.name }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
            <button class="mat-button edit-report-form-button edit-report-form-button-primary"
                    matTooltip="Lagre som nytt rapportoppsett" (click)="doShowCreateNewReportSettingsArea()">
              <mat-icon>add</mat-icon>
            </button>
            <button class="mat-button edit-report-form-button edit-report-form-button-primary"
                    matTooltip="Lagre oppsett"
                    [disabled]="!selectedReportUserTemplateId"
                    (click)="persistSelectedUserTemplate()">
              <mat-icon [ngClass]="{'edit-report-disabled-icon': !selectedReportUserTemplateId}">save</mat-icon>
            </button>
            <button class="mat-button edit-report-form-button edit-report-form-button-end"
                    matTooltip="Slett lagret oppsett"
                    [disabled]="!selectedReportUserTemplateId"
                    (click)="doShowDeleteReportSettingsArea()">
              <mat-icon [ngClass]="{'edit-report-disabled-icon': !selectedReportUserTemplateId}">delete</mat-icon>
            </button>
          </div>

          @if (showCreateNewReportSettingsArea) {
            <div class="edit-report-edit-title-overlay"></div>
            <div class="edit-report-edit-title-area">
              <h1>Lagre gjeldende utvalg som et nytt lagret oppsett for fremtidige rapporter.</h1>
              <h1>Tittel for oppsettet</h1>
              <input class="form-field" type="text" name="title" placeholder="Gi det lagrede oppsettet et navn."
                     [(ngModel)]="newReportSettings.name"/>

              <div class="text-right">
                <button mat-button (click)="doCancelPersistingNewReportSettings()">Avbryt</button>
                <button mat-button color="accent" (click)="doPersistNewReportSettings()">Lagre</button>
              </div>
            </div>
          }

          @if (showDeleteReportSettingsArea) {
            <div class="edit-report-edit-title-overlay"></div>
            <div class="edit-report-edit-title-area">
              <h1>Slett lagret oppsett</h1>
              <h1>Er du sikker på at du vil slette dette oppsettet?</h1>
              <div class="text-right" style="margin-top: 20px;">
                <button mat-button (click)="showDeleteReportSettingsArea = false;">Avbryt</button>
                <button mat-button color="accent" (click)="doDeleteReportSettings()">Slett</button>
              </div>
            </div>
          }


          <h1>Rapportutseendet {{ this.selectedReportViewType?.id }}</h1>
          <div>
            <mat-form-field>
              <mat-label>Velg Rapportutseende</mat-label>
              <mat-select [(ngModel)]="this.selectedReportViewType" (selectionChange)="reportViewTypeChanged()">
                @for (reportView of this.reportViewTypes; track reportView) {
                  <mat-option [value]="reportView">{{ reportView.name }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>

          <h1>Visningsvalg
            @if (!showViewOptions) {
              <span class="edit-report-header-area-button accent float-right"
                    (click)="doShowViewOptions()">Vis valgene</span>
            } @else {
              <span class="edit-report-header-area-button accent float-right" (click)="doHideViewOptions()">Skjul valgene</span>
            }
          </h1>

          @if (showViewOptions) {
            <div class="edit-report-two-column-area">
              @for (viewChoice of this.viewOptionsCheckboxValues; track viewChoice) {
                <label><input type="checkbox" [checked]="viewChoice.selected" (change)="toggleViewChoice(viewChoice)"
                              [attr.disabled]="!this.selectedReportViewType || this.selectedReportViewType.viewChoices.indexOf(viewChoice.key) == -1 ? true : null"/> {{ viewChoice.label }}</label>
              }
            </div>
          }

          <h1>Feltvalg
            <span class="float-right">
              <span class="edit-report-header-area-button accent"
                    (click)="markAllTopLevelFields(true)">Merk alle</span>
            <span class="edit-report-header-area-button"
                  (click)="markAllTopLevelFields(false)">Fjern alle</span>
            <span class="edit-report-header-area-button float-right">Søk i listen</span>
            </span>
          </h1>
        </div>
      }

      @if (this.generatedReport.reportStatus !== 'generated' && this.generatedReport.fieldSections) {
        <div class="report-collapsible-list"
             [dragula]="dragulaId"
             [(dragulaModel)]='generatedReport.fieldSections'>

          @for (field of generatedReport.fieldSections; track field) {
            <div class="report-collapsible-list-item">
              <div class="report-collapsible-list-item-header">
                <mat-slide-toggle [(ngModel)]="field.isSelected"
                                  (click)="toggleTopLevelField(field)"></mat-slide-toggle>
                <span class="report-collapsible-list-item-label"
                      (click)="toggleFieldExpanded(field)">{{ field.title | translate }}
                  ({{ field.fields.length }})</span>
                <span class="report-collapsible-list-item-actions">
                  @if (field.isExpanded) {
                    <mat-icon (click)="toggleFieldExpanded(field)">keyboard_arrow_down</mat-icon>
                  } @else {
                    <mat-icon (click)="toggleFieldExpanded(field)">keyboard_arrow_up</mat-icon>
                  }
                  <mat-icon class="handle">drag_indicator</mat-icon>
                </span>
              </div>

              @if (field.isExpanded) {
                @for (child of field.fields; track child) {
                  <div class="report-collapsible-list-child-item">
                    <div class="report-collapsible-list-child-item-header">
                      <mat-slide-toggle [(ngModel)]="child.isSelected"
                                        (click)="toggleSubField(field)"></mat-slide-toggle>
                      <span>{{ child.title ? (child.title | translate) : (child.admin_title | translate) }}</span>
                      <span class="report-collapsible-list-item-actions">
                      <!--mat-icon>drag_indicator</mat-icon-->
                    </span>
                    </div>
                  </div>
                }
              }
            </div>
          }
        </div>
      }
    }
  </div>
  <div class="edit-report-template-container-right">
    @if (this.generatedReport) {
    <div class="edit-report-preview-area">
        <report-preview [reportTemplate]="generatedReport" [viewOptionsCheckboxValues]="viewOptionsCheckboxValues"
                        preview="true" limit="10"></report-preview>
      @if (downloadPdfStarted()) {
        <div class="generation-in-progress-notification">Henter PDF rapport...</div>
      }
      @if (reportGenerationInProgress()) {
        <div class="generation-in-progress-notification">Genererer Rapport i bakgrunnen. Når den er ferdig kan den lastes ned fra Rapportoversikten</div>
      }
      <div class="edit-report-button-area">
        @if (this.generatedReport.reportStatus === 'new') {
          <button [disabled]="reportGenerationInProgress()" mat-button color="accent" (click)="persistUserReport()">Lagre Endringer</button>
        } @else {
          &nbsp;
        }

        <div class="edit-report-button-area-small-left-margin">* Forhåndsvisningen er begrenset til 10 gjenstander
        </div>

        <div class="edit-report-buttons">
          @if (this.generatedReport.reportStatus !== 'generated') {
            <button [disabled]="reportGenerationInProgress() || this.generatedReport.reportStatus === 'generating'" mat-button>AVBRYT</button>
            <button [disabled]="reportGenerationInProgress() || this.generatedReport.reportStatus === 'generating'" mat-button color="accent"
                    (click)="requestReportPdf(this.generatedReport.id)">
                @if (reportGenerationInProgress() || this.generatedReport.reportStatus === 'generating') {
                  PDF LAGES...
                } @else {
                  LAG RAPPORT
                }
            </button>
          } @else {
            <button [disabled]="downloadPdfStarted()" mat-button color="accent"
                    (click)="downloadSinglePdf(this.generatedReport.id)">LAST NED PDF
            </button>
          }

        </div>
      </div>
    </div>
      }
  </div>
</div>
