import {Component} from '@angular/core';
import {CurrentObjectContext} from '../current-object.context';
import {OverviewFieldsModule} from '../../overview-fields/overview-fields.module';
import {ObjectViewModule} from '../../object-view/object-view.module';
import {M3GlobalKplStylesComponent} from '../../m3-global-kpl-styles/m3-global-kpl-styles.component';
import {OperationsModule} from '../../operations/operations.module';
import {M3WrapperComponent} from '../../m3-wrapper/m3-wrapper.component';
import {MatCard} from '@angular/material/card';
import {ObjectOverviewFieldsV2Component} from '../ui/object-overview-fields-v2/object-overview-fields-v2.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatProgressSpinner} from '@angular/material/progress-spinner';

@Component({
  selector: 'app-object-page-v2-overview',
  standalone: true,
  imports: [OverviewFieldsModule, ObjectViewModule, M3GlobalKplStylesComponent, OperationsModule, M3WrapperComponent, MatCard, ObjectOverviewFieldsV2Component, TranslateModule, MatProgressSpinner],
  templateUrl: './object-page-v2-overview.component.html',
  styleUrl: './object-page-v2-overview.component.scss'
})
export class ObjectPageV2OverviewComponent {

  constructor(
    private currentObjectContext: CurrentObjectContext,
  ) { }

  get sectionsContainer() {
    return this.currentObjectContext.sectionsContainer
  }

  get isEditing() {
    return this.currentObjectContext.isEditing
  }

  get contentInfo() {
    return this.currentObjectContext.contentInfo
  }

  get operationContainer() {
    return this.currentObjectContext.operationContainer;
  }

  get operationStep() {
    return this.currentObjectContext.operationContainer?.currentOperation?.$$currentStep;
  }

  get loading() {
    return this.currentObjectContext.loadingArtifact;
  }
}
